// src/ActuatorList.js
import React, { useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';

const ActuatorList = () => {
  const [actuators, setActuators] = useState([]);
  const [error, setError] = useState(null);
  const location = useLocation();

  useEffect(() => {
    fetch('https://rapi.directus.app/items/demo', {
      headers: {
        'Authorization': `Bearer EBepcil2EOMrJnVxGDBBQljRRK1Pyw1c`
      }
    })
      .then(response => {
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        return response.json();
      })
      .then(data => {
        if (data && Array.isArray(data.data)) {
            console.log('Fetched actuators:', data.data);
          setActuators(data.data);
        } else {
          setError('Expected an array of actuators');
        }
      })
      .catch(error => {
        console.error('Error fetching actuators:', error);
        setError('Failed to fetch actuators');
      });
  }, []);

  if (error) {
    return <div>Error: {error}</div>;
  }

  return (
    <div className='w-64 h-full bg-gray-800 text-white shadow-lg fixed'>
      <h2 className='p-6 text-xl font-bold bg-white bg-gray-600'>Actuators</h2>
      <ul className='list-none p-0'>
        {actuators.map(actuator => (
          <li
            key={actuator.id}
            className={`p-4 ${location.pathname === `/actuator/${actuator.id}` ? 'bg-red-500 font-semibold' : 'font-medium'}`}
          >
            <Link to={`/actuator/${actuator.id}`} className='block'>
              {actuator.name}
            </Link>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default ActuatorList;
