// src/ActuatorDetail.js
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import ActuatorList from './ActuatorList'

const ActuatorDetail = () => {
  const { id } = useParams();
  const [actuatorData, setActuatorData] = useState(null);
  const [error, setError] = useState(null);

  useEffect(() => {
    fetch(`https://rapi.directus.app/items/demo/${id}`, {
      headers: {
        'Authorization': `Bearer EBepcil2EOMrJnVxGDBBQljRRK1Pyw1c`
      }
    })
      .then(response => {
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        return response.json();
      })
      .then(data => {
        if (data && data.data) {
          setActuatorData(data.data);
        } else {
          setError('No data found for this actuator');
        }
      })
      .catch(error => {
        console.error('Error fetching actuator data:', error);
        setError('Failed to fetch actuator data');
      });
  }, [id]);

  if (error) {
    return <div>Error: {error}</div>;
  }

  if (!actuatorData) {
    return <div>Loading...</div>;
  }

  return (
    <div className='flex'>
        <ActuatorList />
        <div className="ml-64 p-8">
        <h1 className="text-2xl font-semibold">{actuatorData.name}</h1>
        <table className="table-auto w-full mt-4">
          <thead>
            <tr>
              <th className="px-4 py-2">Attribute</th>
              <th className="px-4 py-2">Value</th>
            </tr>
          </thead>
          <tbody>
            {Object.entries(actuatorData).map(([key, value]) => (
              <tr key={key}>
                <td className="border px-4 py-2">{key}</td>
                <td className="border px-4 py-2">{value !== null ? value.toString() : 'N/A'}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default ActuatorDetail;
